@import "../../styles/mixins";

.crosshair {
  display: block;
  max-width: $content-width;
  margin: 0 auto;
  padding: 0 2rem;
}

@media all and (max-width: $mobile-breakpoint) {
  .homepage {
    padding: 0 1rem;
  }
}
