@import "../../styles/mixins";

.crosshair {
  &__heading {
    margin-bottom: 2.5rem;
    text-align: right;

    &Text {
      @include fontSize(18px);
      @include monospace;
      margin: 0;
      color: $kovaak-orange;
    }
  }

  &__error {
    @include fontSize(14px);
    font-style: italic;
    color: $error-light;
    letter-spacing: 0.1em;
    line-height: 2.5em;
    margin-top: 0;

    &:before {
      content: "⚠️";
      @include fontSize(24px);
      font-style: normal;
    }
  }

  &__section {
    display: inline-block;
    width: 66%;
    vertical-align: top;

    &.wide {
      width: 66%;
    }

    &.thin {
      width: 33%;
    }
  }

  &__title {
    display: block;
    width: 100%;

    @include headingStyle;
    @include fontSize(18px);

    text-transform: uppercase;

    border-bottom: 1px solid $kovaak-orange;
    margin-top: 0;
  }

  &__display {
    border: 1px solid $kovaak-blue;
    background-color: rgba($kovaak-blue, 0.1);
    background-position: center center;
    background-size: cover;

    width: 100%;
    height: 50vh;
    min-height: 300px;
    max-height: 500px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: none;
    .crosshair__reticle {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%,-50%);
    }
  }
}

.imageChange {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
  padding: 0;
  width: 100%;

  text-align: center;

  &__option {
    width: 20%;
    height: auto;
    cursor: pointer;

    &.active {
      .imageChange {
        &__bgName {
          color: $kovaak-orange;
        }
        &__image {
          border: 1px solid $kovaak-orange;
        }
      }
    }
  }

  &__bgName {
    @include fontSize(13px);
    margin: 0.33em 0;
  }

  &__image {
    max-width: 50%;
    width: 100%;
    border: 1px solid $kovaak-gray;
    user-select: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .crosshair {
    &__heading {
      text-align: center;
      .linkButton {
        height: auto;
      }
    }

    &__heading {
      display: block;

      .linkButton {
        margin: 0 auto;
      }
    }

    &__section {
      width: 100%;

      &.wide,
      &.thin {
        width: 100%;
      }
    }

    &__display {
      display: block;
      margin: 0 auto;
      width: 85vw;
      height: 350px;
      min-height: auto;
    }
  }
}
