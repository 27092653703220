@import "../../../styles/mixins";

// SIZE STYLES

.small {
  display: block;
  @include sansPt;
  @include fontSize(14px);
  text-transform: uppercase;
  font-weight: 700;

  border: 0;
  border-radius: 1em;
  margin: 2em auto 0.66em;
  padding: 0 1.5em;

  min-width: 10em;
  line-height: 2rem;

  cursor: pointer;
  transition: 500ms;
}

.large {
  display: block;
  @include sansPt;
  @include fontSize(16px);
  text-transform: uppercase;
  font-weight: 700;

  border: 0;
  border-radius: 1em;
  margin: 2em auto 0;
  padding: 0 3em;

  min-width: 14em;
  line-height: 3rem;

  cursor: pointer;
  transition: 500ms;
}

// DESIGN STYLES

.lightButton {
  color: $kovaak-orange;
  border: 2px solid $kovaak-orange;
  background: transparent;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $black;
    background: $kovaak-orange;
    outline: 0;

    box-shadow: 0 0 0 1em transparent;
    animation: pulse 1s;
  }

  &:active {
    letter-spacing: 0.2em;
  }
}

.deleteButton {
  @include monospace;
  @include fontSize(14px);

  margin: 0 10px;
  padding: 0.33em 0.75em;
  // width: 100px;
  transition: 500ms;
  background: transparent;
  border-radius: 0.33em;
  // border: 1px solid $kovaak-orange;
  border: 0;

  font-weight: 700;
  color: $white;
  text-align: center;

  --balloon-color: #ff621f;
  --balloon-text-color: #000;
  --balloon-font-weight: 700;
  --balloon-font-size: 14px;

  cursor: help;

  &:after {
    // letter-spacing: 0.075em;
    background-color: $white;
  }

  &:focus,
  &:hover {
    outline: 0;
    background: rgba($kovaak-orange, 0.2);

    box-shadow: 0 0 0 1em transparent;
    animation: pulse 1s;
  }
}

.colorButton {
  display: inline-flex;

  $border-color-outside: $white;
  $border-color: $black;
  $size: 1.5rem;

  &__display {
    display: inline-block;
    vertical-align: top;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    margin: 0 1.2em 0 0;

    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: calc(1.5rem + 4px);
      height: calc(1.5rem + 4px);
      border: 1px solid $border-color-outside;
      border-radius: 100%;
      position: relative;
      top: -3px;
      left: -3px;
    }
  }

  &__button {
    display: inline-block;
    vertical-align: top;
    line-height: $size;
    padding: 0 1.5em;
    margin: 0 0.25em;
    width: 7.5em;
    border: 0;
    background-color: transparent;
    text-decoration: underline;

    cursor: pointer;

    @include fontSize(12px);
    @include sansSerif;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-weight: 700;
    color: $white;

    transition: 300ms;

    &:hover {
      color: rgba($kovaak-orange, 1);
      outline: 0;
    }

    &:focus {
      outline: 0;
    }

    &:active {
      background-color: $white;
      outline: 0;
    }
  }
}

.linkButton {
  cursor: pointer;

  display: block;
  height: 1.5em;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  border-bottom: 1px solid $kovaak-orange;

  transition: 500ms;

  @include fontSize(16px);
  color: $kovaak-orange;

  &:active,
  &:hover {
    outline: 0;
    color: $kovaak-orange-light;
    border-color: $kovaak-orange-light;
  }
}

// ===== KEYFRAMES ======

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba($kovaak-orange, 0.4);
  }
}
