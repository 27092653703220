@import "../../styles/mixins";

.header {
  padding: 2rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__title {
    @include fontSize(36px);
    font-family: "Heavitas", "Source Sans Pro", "Franklin Gothic Medium", "Arial Narrow", Arial,
      sans-serif;
    font-weight: 300;
    letter-spacing: 0.1em;
    color: $kovaak-orange;

    display: inline-block;
    margin: 0 1em 0 0;

    &--big {
      font-size: 1.33em;
    }
  }

  &__subtitle {
    @include fontSize(26px);
    font-family: "Metropolis", "Source Sans Pro", "Franklin Gothic Medium", "Arial Narrow", Arial,
      sans-serif;
    letter-spacing: 0.33em;

    margin: 0;
    display: inline-block;

    font-family: "Source Sans Pro", sans-serif;
  }

  &__meta {
    @include fontSize(13px);
    display: block;
  }

  &__leftBlock,
  &__rightBlock {
    display: inline-block;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .header {
    padding-top: 1rem;

    &__title {
      display: block;
      margin: 0 0 0.33em;
    }

    &__leftBlock,
    &__rightBlock {
      display: block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }
}
