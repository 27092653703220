@import "../../styles/mixins";

.examples {
  margin: 4rem 0 3rem;

  &__title {
    display: block;
    width: 100%;

    @include headingStyle;
    @include fontSize(15px);

    text-transform: uppercase;

    border-bottom: 1px solid $kovaak-orange;
    margin-top: 0;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  &__listItem {
    display: inline-block;
    margin: 0 1em;
    cursor: pointer;

    @include fontSize(14px);
  }

  &__name {
    letter-spacing: 0.1em;
    text-align: center;
  }

  &__img {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    padding: 1rem;
    // border: 1px solid rgba($kovaak-orange, 0.4);
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .examples {
    &__list {
      &Item {
        width: 20vw;
      }
    }
  }
}
