@import "../../styles/mixins";

.instructions {
  @include fontSize(16px);
  @include sansPt;

  vertical-align: top;
  align-items: flex-start;
  line-height: 1.75em;

  &__title {
    @include headingStyle;
    @include fontSize(26px);
    text-transform: uppercase;
    margin-bottom: 1.5em;
  }

  &__step {
    margin: 0 0 2rem;
  }

  &__heading {
    width: 100%;
    margin: 0 0 0.5em;
    padding-bottom: 0.2em;
    border-bottom: 1px solid $white;
    color: $kovaak-orange;
    @include sansSerif;
  }

  &__stepNumber {
    margin-right: 0.5em;
    display: inline-block;
    color: $white;
  }

  &__paragraph {
    display: block;
    margin: 0.66em 0;
  }

  code {
    word-break: break-all;
  }

  &__highlight {
    color: $kovaak-orange;
  }

  &__note {
    font-style: italic;
  }

  &__image--large,
  &__image--small {
    display: block;
    margin: 2em auto;
  }

  &__image--large {
    width: 50vw;
    max-width: 800px;
  }

  &__image--small {
    width: 25vw;
    max-width: 350px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .instructions {
    &__image--large {
      width: 85vw;
      max-width: auto;
    }

    &__image--small {
      width: 50vw;
      max-width: auto;
    }
  }
}
