@import "../../../styles/mixins", "./rangeInputRestyle";

.slider {
  display: inline-block;
  width: 55%;

  &.alpha::-webkit-slider-runnable-track {
    background: none;
    background-image: linear-gradient(90deg, transparent 0%, $kovaak-orange 100%);
    border: 0;
  }

  &.alpha::-moz-range-track {
    background: none;
    background-image: linear-gradient(90deg, transparent 0%, $kovaak-orange 100%);
    border: 0;
  }

  &::-webkit-slider-thumb:hover {
    background-color: $white;
  }

  &::-moz-range-thumb:hover {
    background-color: $white;
  }

  &::-ms-thumb {
    background-color: $white;
  }
}
