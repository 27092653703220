@import "../../styles/mixins";

.footer {
  padding: 2em 1em;
  margin: 2em 0 0;
  text-align: center;

  background-color: $gray-dark;

  &__content {
    display: block;
    margin: 0 auto;
    max-width: $content-width;

    @include sansSerif;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__listItem {
    display: inline-block;
    margin-right: 2em;
  }

  &__link {
    color: $kovaak-orange;
  }

  .legal {
    @include fontSize(12px);
    margin: 2rem 0 0;
    color: $gray-mid;
    word-break: keep-all;
  }
}
