@import "../../../styles/mixins";

.colors {
  &__rgbGroup {
    display: inline-flex;
    width: 50%;
    justify-content: space-between;
  }

  &__hexCode {
    @include monospace;
    @include fontSize(16px);

    width: 9em;
    margin-left: 0;
    margin-bottom: 0.66em;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $white;

    color: $white;
    text-align: center;
    letter-spacing: 0.2em;
    font-weight: 400;
  }
}
