@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&Source+Code+Pro&family=Source+Sans+Pro:wght@300;400;700&display=swap");

@font-face {
  font-family: Heavitas;
  src: url(./fonts/Heavitas.ttf);
}

@font-face {
  font-family: Metropolis;
  src: url(./fonts/Metropolis-Medium.otf);
}

$content-width: 1200px;
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 762px;

// ==== SITE COLORS

$black: #000;
$gray-light: #dadada;
$gray-mid: #9a9a9a;
$gray-dark: #111;
$white: #fff;

$error-light: #ff3328;
$error-dark: #aa1325;
$warning: #fad200;

$kovaak-blue: #21406c;
$kovaak-gray: #bfb9bd;
$kovaak-orange: #ff621f;
$kovaak-orange-light: #ffa026;

// ===== MIXINS

@mixin headingStyle {
  @include fontSize(50px);
  font-family: "Source Sans Pro", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: 0.1em;
}

@mixin sansPt {
  font-family: "Source Sans Pro", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 400;
}

@mixin sansSerif {
  font-family: "Metropolis", "Montserrat", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  font-weight: 400;
}

@mixin monospace {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

//  ==== SASS FUNCTIONS

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
