@import "../../../styles/mixins";

.checkbox {
  display: flex;
  margin-right: 1.33rem;
  margin-left: 1rem;
  width: 1.33rem;
  height: 1.33rem;
  cursor: pointer;
}
