@import "./mixins", "balloon";

body {
  margin: 0;
  background-color: $black;
  color: $white;

  @include sansSerif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.dev_only {
  color: $kovaak-orange;

  &:before {
    content: "dev only: ";
    margin-left: 1em;
  }
}

@media all and (max-width: $mobile-breakpoint) {
  html {
    font-size: 14px;
  }
}
