@import "../../../styles/mixins";

.crosshairSelect {
  @include sansSerif;
  font-size: 0.875rem;
  letter-spacing: 0.125em;
  color: $white;

  width: auto;
  min-width: 55%;
  padding: 0.33em 0.5em;
  background-color: rgba($black, 0.33);
  border: 0;
  transition: 300ms;

  &:hover {
    background-color: rgba($black, 0.9);
    cursor: pointer;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }
}
