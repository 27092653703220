@import "../../styles/mixins";

.share {
  margin: 3rem 0;

  &__title {
    display: block;
    width: 100%;

    @include headingStyle;
    @include fontSize(15px);

    text-transform: uppercase;

    border-bottom: 1px solid $kovaak-orange;
    margin: 0 0 1.5em 0;
  }

  &__columns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__linkURL {
    @include fontSize(12px);
    word-break: break-word;
    color: $white;

    margin-right: 4em;
    padding: 0.33em 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $kovaak-gray;
    width: 100%;
  }

  .lightButton {
    margin: 0;
    @include fontSize(13px);
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .share {
    &__columns {
      flex-wrap: wrap;

      .lightButton {
        margin-bottom: 3em;
      }
    }
  }
}
