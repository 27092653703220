@import "../../../styles/mixins";

// global slider styles
$bgColor: $kovaak-orange;

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  // width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  transition: 300ms;
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 1.5rem;
  width: 1rem;
  border-radius: 3px;
  background: $kovaak-gray;
  cursor: pointer;
  margin-top: -0.5rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  transition: 300ms;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 1.5rem;
  width: 1rem;
  border-radius: 3px;
  background: $kovaak-gray;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  transition: 300ms;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 1.5rem;
  width: 1rem;
  border-radius: 3px;
  background: $kovaak-gray;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $bgColor;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

// input[type="range"]:focus::-webkit-slider-runnable-track {
//   background: $kovaak-orange;
// }

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $bgColor;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: $bgColor;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-lower {
  background: $bgColor;
}
input[type="range"]::-ms-fill-upper {
  background: $bgColor;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: $bgColor;
}
