@import "../../styles//mixins";

.navigation {
  display: inline-block;
  width: 20rem;

  &__list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
  }

  &__listItem {
    display: inline-block;
  }

  &__link {
    @include sansPt;
    @include fontSize(13px);

    color: $white;
    text-decoration: none;
    text-transform: uppercase;
  }

  &--mobile {
    display: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .navigation {
    display: none;
    margin: 0 auto;

    &__mobileMenu {
      transition: 500ms;
    }

    &--mobile {
      @include sansPt;
      @include fontSize(20px);
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1.6em;

      display: block;
      margin: 0 auto 2rem;
    }
  }
}
