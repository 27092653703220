@import "../../styles/mixins";

.controls {
  display: inline-block;
  position: relative;
  padding: 0 0 0 1rem;
  width: calc(100% - 1rem);

  &__list {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 1rem;
    list-style-type: none;
  }
}

.controlGroup {
  margin: 0.5rem 0;
  width: 100%;

  &__heading {
    @include fontSize(14px);

    cursor: pointer;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: center;
    margin: 0 0 2px;
    line-height: 2em;
    padding: 0.8em 0.33em 0.8em 1.33em;
    box-shadow: inset 0 0 0 1000px rgba($kovaak-orange, 0.03), inset 0px 0px 6px $white;
    background-image: url("../../images/chalkboard.jpg");

    letter-spacing: 0.1em;
    text-transform: uppercase;

    &Text {
      max-width: 80%;
    }

    &.closed {
      box-shadow: inset 0 0 0 1000px rgba($kovaak-orange, 0.03);
    }
  }
}

.controlSection {
  background-image: url("../../images/chalkboard.jpg");
  border-left: 6px solid rgba($kovaak-orange, 0.25);
  padding: 1rem 3%;
  width: calc(94% - 6px);

  &__heading {
    display: block;
    width: 100%;
    margin: 1.5em 0 1em;

    @include sansPt;
    @include fontSize(15px);
    letter-spacing: 0.1em;

    text-transform: uppercase;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__group {
    display: flex;
    padding-right: 1em;
    margin-bottom: 0.33rem;
    position: relative;
    left: 0.5em;
    align-items: center;
    justify-content: space-between;
  }

  &__aside {
    position: relative;
    padding: 0.75em 0 1.5em;
    margin: 1.66em 0;
    background-color: rgba($kovaak-gray, 0.1);
    border-bottom: 1px solid rgba($kovaak-gray, 0.2);

    &.showArrow {
      &:before {
        content: "";
        position: absolute;
        top: -1em;
        right: 1em;
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid rgba($kovaak-gray, 0.1);
      }
    }
  }

  &__identifier {
    @include fontSize(12px);
    margin-left: 1em;

    &:first-of-type {
      margin: 0;
    }
  }

  &__label,
  &__valueInput,
  &__valueDisplay {
    display: inline-block;
    @include sansPt;

    width: 16%;
    margin-top: 0.15em;
    margin-bottom: 0.15em;
  }

  &__label {
    @include fontSize(14px);
    margin-right: 5%;
  }

  &__valueDisplay {
    @include fontSize(18px);
    margin-left: 3%;
    text-align: center;

    transition: 300ms;

    &:hover,
    &:focus {
      border-bottom-color: $kovaak-orange;
    }
  }

  &__valueInput {
    @include fontSize(18px);
    text-align: center;
    color: $white;

    width: 2.5em;
    margin-left: 4%;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $white;

    transition: 300ms;

    &:hover,
    &:focus {
      // color: $kovaak-orange;
      border-bottom-color: $kovaak-orange;
    }

    &.rgbNum {
      @include fontSize(14px);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__flair {
    // degrees symbol for rotate input
    position: absolute;
    top: 0;
    right: 1.33em;
  }

  &__flex {
    display: flex;
    margin: 1rem 0;
    align-items: center;
    justify-content: space-between;

    .controlSection__heading {
      margin-bottom: 0;
    }

    .controlSection__label {
      cursor: pointer;
      width: auto;
      margin-left: 0.5rem;
    }
  }
}

.sectionBreak {
  display: block;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba($kovaak-orange, 0.2);
  margin: 1.33rem 0;
}

.pickerBar {
  margin: 1rem 0;
}

@media only screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint) {
  .controlSection {
    &__label {
      width: 33%;
    }
  }

  [data-balloon-pos]:after,
  [data-balloon-pos]:before {
    display: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .controls {
    padding: 0;
    display: block;
    margin: 0 auto;
  }

  [data-balloon-pos]:after,
  [data-balloon-pos]:before {
    display: none;
  }
}
